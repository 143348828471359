// import axios from "axios";
// import { useRef } from "react";
import "./Footer.css";

function NostrSubscription() {
  // const [errorMessage, setErrorMessage] = useState(null);
  // const [subscribed, setSubscribed] = useState(false);

  // function handleSubmit(event) {
  //   event.preventDefault();
  //   axios
  //     .post("https://hitchhikersguidethroughthemetaverse.info/api/subscribe", {
  //       subscriber: event.target.pubKey.value,
  //     })
  //     .then(function (response) {
  //       setErrorMessage(null);
  //       setSubscribed(true);
  //     })
  //     .catch(function (error) {
  //       setErrorMessage(error.response.data.error);
  //     });
  // }

  return (
    <footer>
      {
        <div className="intro-text-no-anim center">
          <div className="marquee">
            Love these stories? Support my writing with sats at the Lightning
            address below! Your contribution helps create more such adventures.
            Thanks!
          </div>
          {/* <label style={{ color: "yellow", fontSize: "18px" }}>
            groomedtoy89@walletofsatoshi.com
          </label> */}
          <a
            className="matrix-button"
            href="lightning:lnurl1dp68gurn8ghj7ampd3kx2ar0veekzar0wd5xjtnrdakj7tnhv4kxctttdehhwm30d3h82unvwqhkwun0dakk2er5daunswg8s3rfy"
            style={{ fontSize: "8px", maxHeight: "10px" }}
          >
            ⚡ Support the Author
          </a>
          <img
            src="https://mirrors.creativecommons.org/presskit/buttons/88x31/svg/by-sa.svg"
            style={{
              position: "absolute",
              bottom: "25px",
              right: "5px",
              width: "80px",
              height: "30px",
            }}
            alt="Creative Commons License"
          />
        </div>
      }
    </footer>
  );
}

export default NostrSubscription;
