export async function fetchGameStory(callback, url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        callback(Math.round((event.loaded / event.total) * 100));
      }
    };
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(Error("Network Error"));
    };
    xhr.send();
  });
}

export function checkPrerequisites(prerequisites, visitedStates, items, money) {
  let visitedStatesSet = new Set(visitedStates);
  if (!prerequisites) return true;
  return !prerequisites.some((p) => {
    if (p.type === "visited") {
      return !visitedStatesSet.has(p.id);
    } else if (p.type === "not visited") {
      return visitedStatesSet.has(p.id);
    } else if (p.type === "item") {
      return !items.has(p.id);
    } else if (p.type === "money") {
      return money <= p.amount;
    }
    return false;
    // Add additional checks for different prerequisites functions here
  });
}
export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
