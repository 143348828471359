import React from "react";
import "../../App.css";
import "./Modal.css";
// import { RiCloseLine } from "react-icons/ri";

const Modal = ({ setIsOpen, heading, content, onOkClick }) => {
  return (
    <>
      <div className={"darkBG"} onClick={() => setIsOpen(false)} />
      <div className={"centered"}>
        <div className={"modal"}>
          <div className={"modalHeader"}>
            <h5 className={"heading"}>{heading}</h5>
            <div>
              <button className={"closeBtn"} onClick={() => setIsOpen(false)}>
                x
              </button>
            </div>
          </div>

          <div className={"modalContent"}>{content}</div>
          <div className={"modalActions"}>
            <div className={"actionsContainer"}>
              <button
                className={"deleteBtn play-game-button matrix-style"}
                onClick={onOkClick}
              >
                Read
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
