import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";

function Header() {
  const navigate = useNavigate();

  return (
    <header className="matrix-header">
      <button
        className="header-button"
        onClick={() => {
          navigate("/");
        }}
      >
        Stories From The Metaverse!
      </button>
    </header>
  );
}

export default Header;
