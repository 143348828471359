import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

function generateImages(state, index) {
  console.log("state", state, "index", index);
  if (!state.images) return null;
  let validImages = [];
  state.images.forEach((image) => {
    let imageIndex = 0;
    if (image.atIndex) imageIndex = image.atIndex;
    if (imageIndex === index) {
      validImages.push(image);
    }
  });
  return validImages.map((image, index) => {
    return (
      <div className="image-text" key={index}>
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          className="matrix-image"
        />
        <br />
        {image.text}
        {image.by && (
          <>
            {" "}
            by: <a href={image.by.link}>{image.by.name}</a>
          </>
        )}
      </div>
    );
  });
}

function getContent(state, index, linesPerPage) {
  let newlines = state.text.slice(index, index + linesPerPage);
  let newContent = [];
  let images = generateImages(state, index);
  console.log(images, "Images");
  if (images && images.length !== 0) newContent.push(images);
  if (newlines)
    newContent.push(
      <ReactMarkdown key={index} children={newlines.join("\n")} />
    );
  return newContent;
}

function ContentDisplayer(props) {
  const { state, linesPerPage, onFinish } = props;
  const [currentIndex, setCurrentIndex] = useState(0); // Initialize state variable to track current index
  const [isDisplayed, setIsDisplayed] = useState(true); // Initialize state variable to track whether all text has been displayed
  const [content, setContent] = useState([]);
  const lines = state.text;

  useEffect(() => {
    setCurrentIndex(0);
    setIsDisplayed(true);
    setContent(getContent(state, 0, linesPerPage));
    setCurrentIndex(linesPerPage);
  }, [state, linesPerPage]);

  // Function to handle clicking the downward arrow button
  function handleClick() {
    let new_content = getContent(state, currentIndex, linesPerPage);
    if (new_content && new_content.length !== 0)
      setContent([...content, new_content]);

    if (currentIndex + linesPerPage >= lines.length) {
      // All text has been displayed, call the onFinish callback
      setIsDisplayed(false);
      onFinish();
      return;
    }
    setCurrentIndex(currentIndex + linesPerPage);
  }

  return (
    <div>
      {content}
      {isDisplayed && (
        <button
          className="see-more-button triangle"
          onClick={handleClick}
        ></button>
      )}
    </div>
  );
}

export default ContentDisplayer;
