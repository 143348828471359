import React, { useState } from "react";
import "./App.css";
import GameTile from "./components/GameTile";
import { games } from "./GameData";
import NostrSubscription from "./components/Footer/NostrSubscription";
import { Link, useNavigate } from "react-router-dom";
import Modal from "./components/Modal/Modal";
import Feedback from "./components/Feedback";

function App() {
  const [showDescription, setShowDescription] = useState(false);
  const [selectedGame, setSelectedGame] = useState({});
  const navigate = useNavigate();

  const onGameClick = (game) => {
    setSelectedGame(game);
    setShowDescription(true);
  };

  const onReadClick = () => {
    navigate(`story/${selectedGame.id}`);
  };

  return (
    <div>
      <div style={{ marginTop: "90px", paddingBottom: "100px" }}>
        <div className="intro-text center">
          Welcome to the metaverse, choose the lives you wish to visit today.
        </div>
        <div className="gameTileWrapper">
          {games.map((game) => {
            return (
              <Link onClick={() => onGameClick(game)} key={game.id}>
                <GameTile game={game} />
              </Link>
            );
          })}
        </div>
        {showDescription && (
          <Modal
            setIsOpen={setShowDescription}
            heading={selectedGame.title}
            content={selectedGame.description}
            onOkClick={onReadClick}
          />
        )}
        <Feedback />
      </div>
      <div>
        <NostrSubscription />
      </div>
    </div>
  );
}

export default App;
