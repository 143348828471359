import React, { useState } from "react";
import AutoClickButton from "./AutoClickButton";
import { checkPrerequisites } from "../utils/utils";

function OptionsMenu(props) {
  // State to store collapsed/expanded state of menu
  const [collapsed, setCollapsed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  // Toggle collapsed state when button is clicked
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  function handleOptionClick(option) {
    setSelectedOption(option);
    props.onClick(option);
  }

  function handleContinueClick() {
    props.onContinueClick(selectedOption);
    setSelectedOption(null);
  }

  function handleBackButtonClick() {
    setSelectedOption(null);
    props.onBackClick();
  }

  function handleRestartClick() {
    props.onRestartClick();
  }

  return (
    <div className={`matrix-menu ${collapsed ? "collapsed" : ""}`}>
      <button
        onClick={() => toggleCollapsed()}
        className="collapse-button play-game-button matrix-style"
      >
        {collapsed ? "+" : "-"}
      </button>
      {/* Conditionally render menu content based on collapsed state */}

      {!collapsed && (
        <>
          <h1 className="intro-text-no-anim">Options</h1>
          {selectedOption && selectedOption.reply && (
            <p className="intro-text">{selectedOption.reply}</p>
          )}
          <ul>
            {!selectedOption &&
              props.options &&
              props.options.map((option) => {
                return (
                  <li key={option.text.join("\n")}>
                    <button
                      className="play-game-button matrix-style"
                      onClick={() => {
                        handleOptionClick(option);
                      }}
                      disabled={
                        !checkPrerequisites(
                          option.prerequisites,
                          props.visitedStates,
                          props.items,
                          props.bitcoin
                        )
                      }
                    >
                      {option.text.join("\n")}
                    </button>
                  </li>
                );
              })}
            {!props.options && (
              <li key="Restart Button">
                <button
                  className="play-game-button matrix-style"
                  onClick={() => {
                    handleRestartClick();
                  }}
                >
                  See All Stories
                </button>
              </li>
            )}
          </ul>
          {props.visitedStates.length >= 1 && ( // Only show the back button if there's a previous state to go back to
            <button
              className="back-button play-game-button matrix-style"
              onClick={handleBackButtonClick}
            >
              &lt;
            </button>
          )}
          {selectedOption && (
            <AutoClickButton
              text="Continue"
              onClick={handleContinueClick}
              delay={6}
            />
          )}
        </>
      )}
    </div>
  );
}

export default OptionsMenu;
