import React from 'react';

function Loader(props) {
  return (
    <div className="matrix-style">
      <div style={{ cursor: "pointer", fontFamily: "'Courier New', monospace" }}>
        Loading... {props.percentage}%
      </div>
    </div>
  );
}

export default Loader;