import {
  nip04,
  SimplePool,
  generatePrivateKey,
  getPublicKey,
  getEventHash,
  getSignature,
  nip19,
} from "nostr-tools";

import React, { useState } from "react";

import "./Feedback.css";

function Feedback(props) {
  const relays = [
    "wss://relay.damus.io/",
    "wss://offchain.pub/",
    "wss://nos.lol/",
    "wss://relay.nostr.wirednet.jp/",
    "wss://nostr.wine/",
  ];
  const relayPool = new SimplePool();
  const sk = generatePrivateKey();
  const pk = getPublicKey(sk);

  const [Feedback, setFeedback] = useState("");
  const [feedbackSent, setFeedbackSeent] = useState(false);

  const handleInputChange = (event) => {
    setFeedback(event.target.value);
  };

  const sendFeedback = async () => {
    const myPubkey = nip19.decode(
      "npub132vp7xhrl2enqz65338jqe2vkrcax5zf339kdpymw059gcqpmjsq6fm80g"
    ).data;
    let ciphertext = await nip04.encrypt(
      sk,
      myPubkey,
      Feedback + "\n\nFrom Hitchhiker's Guide Through The Metaverse"
    );
    let event = {
      kind: 4,
      pubkey: pk,
      tags: [["p", myPubkey]],
      content: ciphertext,
      created_at: Math.floor(Date.now() / 1000),
    };
    event.id = getEventHash(event);
    event.sig = getSignature(event, sk);
    relayPool.publish(relays, event);
    setFeedbackSeent(true);
  };

  return (
    <>
      <div className="inputWrapper">
        <h1
          style={{
            color: "green",
            fontFamily: "Courier New, Courier, monospace",
          }}
        >
          Write to the Author
        </h1>
        {!feedbackSent && (
          <>
            <textarea
              onChange={handleInputChange}
              placeholder="Send a message to the author.."
              className="matrix-input"
              style={{
                maxWidth: "80%",
                backgroundColor: "black",
                width: "80%",
                fontSize: "18px",
                border: "1px solid green",
                color: "green",
              }}
              rows={
                Feedback.split("\n").length < 10
                  ? 10
                  : Feedback.split("\n").length
              }
            />
            <button
              className="play-game-button matrix-style"
              onClick={sendFeedback}
            >
              Send Feedback
            </button>
          </>
        )}
        {feedbackSent && (
          <span style={{ fontSize: "18px", color: "green" }}>
            Thank you for your feedback!
          </span>
        )}
      </div>
    </>
  );
}

export default Feedback;
