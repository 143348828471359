import React from "react";
import "./GameTile.css";

function GameTile({ game }) {
  const { imageUrl, title, isDisabled } = game;

  return (
    <div
      disabled={isDisabled}
      className={`gameTile ${isDisabled ? "disabledGame" : ""}`}
    >
      <div>
        <img height={150} width={150} src={imageUrl} alt="Game Tile"></img>
      </div>
      <div className="gameText">{title}</div>
    </div>
  );
}

export default GameTile;
